<template>
  <div :class="`${initClass()}`">
    <div class="time-info-main-data-gap time-info-main-dataimg w-100 h-100" v-if="!isOpen">
      <div class="time-info-main-nondata">
        <img :src="require('@/assets/img/nondata.png')" style="object-fit:contain;">
      </div>
      <div class="time-info-main-nondata-text">你想找哪裡的照片？<br>先去搜尋一下吧！</div>
    </div>
    <transition name="photobox-content" v-if="isOpen">
      <div class="time-info-main-data-gap w-100 h-100">
        <p>2020-08-03
          <span class="material-icons">
            expand_more
          </span>
        </p>
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'PhotoBox',
  props: [
    'isOpen',
  ],
  methods: {
    initClass() {
      if (this.initial === '') return 'photobox-initial';
      return '';
    },
  },
};
</script>

<style lang='scss'>
</style>
