import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
// import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import { apiKey } from '@/config/gmap/index';

Vue.use(VueGoogleMaps, {
  load: {
    key: apiKey,
    libraries: ['places', 'drawing'],
    language: 'zh-TW',
    // OR: libraries: 'places,drawing,visualization'
  },
  autobindAllEvents: false,
  installComponents: true,
});
/* Vue.component(GmapCluster, {
  GmapCluster,
}); */
