<template>
  <div class="loading-block" v-if='isLoading' v-bind:class="{ active: isLoading }">
    <img class="animate__animated animate__pulse animate__infinite"
      :src="require('@/assets/img/loading.png')"
    >
  </div>
</template>

<script>
export default {
  name: 'Loading',
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    toggleLoading() {
      this.isLoading = !this.isLoading;
    },
    closeWindow() {
      this.isLoading = false;
    },
  },
};
</script>

<style lang='scss'>
.loading-block {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: rgba($color: #000000, $alpha: .2);
  transition: all 1s ease-out;
  z-index: 999;
  opacity: 0;
}
.loading-block.active {
  opacity: 1;
}
.loading-block img {
  width:210px;
  height: 218px;
  position:absolute;
  top:calc(50% - 105px);
  left:calc(50% - 109px);
}
</style>
