<template>
  <li class="layerbox-item">
    <div class="layer-option" >
      <span
        class="material-icons left"
        v-bind:class="{ active: overlaiesCompare.indexOf(item) !== -1 }"
        v-if='onCompare'
        @click="toggleOverlay"
      >check_circle</span>
      <span
        class="material-icons left"
        v-bind:class="{ active: overlaies.indexOf(item) !== -1 }"
        v-else
        @click="toggleOverlay"
      >check_circle</span>
      <span class="layer-name" @click="toggleExtend">{{ item.name }}</span>
      <span class="material-icons right" v-if='isExtend' @click="toggleExtend">
        expand_less
      </span>
      <span class="material-icons right" v-else @click="toggleExtend">
        expand_more
      </span>
    </div>
    <div
      class="layer-option-hide animate__animated animate__fadeInDown animate__fast"
      v-if='isExtend'
    >
      <span class="txt-border">事件</span> : {{ item.event }}
    </div>
    <div
      class="layer-option-hide animate__animated animate__fadeInDown animate__fast"
      v-if='isExtend'
    >
      <span class="txt-border">標籤</span> : {{ item.tag }}
    </div>
    <div
      class="layer-option-hide animate__animated animate__fadeInDown animate__fast"
      v-if='isExtend'
    >
      <span class="txt-border">拍攝時間</span> : {{ item.time }}
    </div>
    <div
      class="layer-option-hide animate__animated animate__fadeInDown animate__fast"
      v-if='isExtend'
    >
      <button class="btn-yellow btn-radius btn-small">Tiff下載</button>
      <button class="btn-yellow btn-radius btn-small">Raw下載</button>
      <button class="btn-yellow btn-radius btn-small">WMTS下載</button>
    </div>
  </li>
</template>

<script>
export default {
  name: 'LayerBoxItem',
  data() {
    return {
      isExtend: false,
    };
  },
  props: [
    'item',
    'overlaies',
    'overlaiesCompare',
    'onCompare',
  ],
  methods: {
    toggleExtend() {
      this.isExtend = !this.isExtend;
    },
    toggleOverlay() {
      if (!this.onCompare && this.overlaies.indexOf(this.item) === -1) {
        this.$emit('open-layer', this.item);
      } else if (!this.onCompare && this.overlaies.indexOf(this.item) !== -1) {
        this.$emit('close-layer', this.item);
      }
      if (this.onCompare && this.overlaiesCompare.indexOf(this.item) === -1) {
        console.log(1);
        this.$emit('open-compare-layer', this.item);
      } else if (this.onCompare && this.overlaiesCompare.indexOf(this.item) !== -1) {
        this.$emit('close-compare-layer', this.item);
      }
    },
  },
};
</script>

<style lang='scss'>
.btn-yellow{
  background-color:#F9D772;
  border:1px solid #F9D772;
  color:#333;
  transition: all .5s ease-in-out;
  &:hover {
    background-color: #333;
    border:1px solid #333;
    color:#FFF;
  }
}
.btn-small{
  width:31%;
  height: 28px;
  margin:0 1%;
  cursor: pointer;
  display: inline-block;
}
.btn-small.btn-radius{
  border-radius: 14px;
}
.txt-border{
  font-weight: 900;
}
.layerbox-item {
  position: relative;
  display: inline-block;
  width: 100%;
}
.layer-option{
  position: relative;
  line-height: 42px;
  height:42px;
  font-size:16px;
  cursor:pointer;
  background-color: #FFF;
  z-index: 1;
}
.layer-option .layer-name {
  margin-left:28px;
}
.layer-option .material-icons{
  position:absolute;
}
.material-icons.left{
  top:9px;
  left:0px;
  color:#CCC;
  font-size:24px;
  transition: color .5s ease-in-out;
  &:hover {
    color: #ebc655;
  }
  &.active {
    color: #ebc655;
  }
}
.material-icons.right{
  top:12px;
  right:0px;
}
.layerbox-item .hide {
  display:none;
}
.layer-option-hide{
  height: 46px;
  line-height: 46px;
  border-top:1px solid #ccc;
}
.layer-option-hide:last-child{
  border-bottom:1px solid #ccc;
}
</style>
