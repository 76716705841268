<template>
  <div
    v-if="measureOverlays.length > 0"
    class="measure-result card-bg box-shadow border-radius-square-soft"
  >
    <div class="measure-result-wrapper">
      <div class="measure-result-title measure-indent">測量結果</div>
      <ul style="border: 1px solid transparent;">
        <li
          v-for="(item, index) in measureOverlays"
          :key="index"
          class="measure-result-item measure-indent border-radius-square"
          :class="{'measure-result-active': item.uuid === measureInfoWindowData.uuid}"
          @click="changeFocusedData(item)"
        >
          <div class="measure-result-icons">
            <span
              class="material-icons measure-result-icon"
              @click.stop="toggleShow(index)"
            >{{ getIconName(index) }}</span>
            <span
              class="material-icons measure-result-icon"
              @click.stop="deleteOverlay(index)"
            >delete_outline</span>
          </div>
          <span>線段{{index + 1}} {{ getUnitValue(item) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getTwoDecimalPlaces } from '@/utils/format';

export default {
  name: 'MeasureToolResult',
  data() {
    return {
      service: null,
    };
  },
  computed: {
    ...mapGetters(['measureOverlays', 'measureInfoWindowData', 'measureUnit']),
  },
  methods: {
    getIconName(index) {
      return this.measureOverlays[index].isShow === true ? 'visibility' : 'visibility_off';
    },
    getUnitValue(item) {
      const formatValue = getTwoDecimalPlaces(item.unitValue * this.measureUnit.times);
      return `${formatValue} ${this.measureUnit.text}`;
    },
    toggleShow(index) {
      this.$store.dispatch('measureTool/toggleShowOverlay', index);
    },
    deleteOverlay(index) {
      this.$store.dispatch('measureTool/removeOverlay', index);
    },
    changeFocusedData(item) {
      const newItem = JSON.parse(JSON.stringify(item));
      if (newItem.uuid === this.measureInfoWindowData.uuid) {
        this.$store.dispatch('measureTool/setInfoWindowStatus', true);
      } else {
        newItem.arrData = newItem.path;
        delete newItem.path;
        this.$store.dispatch('measureTool/setFocusedOverlay', newItem);
      }
    },
  },
};
</script>

<style lang='scss'>
.measure-result {
  min-width: 280px;
}
.measure-result-wrapper {
  padding: 0 3%;
  text-align: left;
}
.measure-result-title {
  padding: 6% 0;
  position: relative;
  &::after {
    content: '';
    display: block;
    width: 95%;
    margin: 0 auto;
    height: 2px;
    background-color: #EDEDED;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
  }
}
.measure-result-item {
  margin: 3% 0;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color .1s ease-in-out;
  &:hover {
    background: #eee;
  }
  .measure-result-icon {
    font-size: 24px;
    margin-right: 8px;
    cursor: pointer;
  }
}
.measure-result-icons {
  display: flex;
  width: 40%;
  position: relative;
  &::after {
    content: '';
    width: 3px;
    height: 100%;
    position: absolute;
    right: 8px;
    background-color: #333;
  }
}
.measure-indent {
  padding-left: 4%;
  padding-right: 4%;
}
.measure-result-active {
  position: relative;
  z-index: 1;
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #FDEEC2;
  }
}
</style>
