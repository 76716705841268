<template>
  <div class="address-info box-shadow border-radius-square-soft">
    <div class="flex">
      <div class="address-info-img address-info-gap">
        <div>
          <span class="material-icons">fmd_good</span>
        </div>
        <div class="address-info-img-label">位置資訊</div>
      </div>
      <div class="address-info-gap address-info-block" v-for="(item, index) in labels" :key="index">
        <div class="address-info-label">{{ item.name }}</div>
        <div class="address-info-value">{{ item.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddressInfo',
  props: [
    'labels',
  ],
};
</script>

<style lang='scss'>
.address-info {
  width: 80%;
  position: absolute;
  left: 50%;
  bottom: 8%;
  z-index: 3;
  transform: translate3d(-50%, 0, 0);
  background-color: #F9D772;
}
.address-info-img {
  flex: 1;
}
.address-info-img .material-icons {
  font-size: 36px;
}
.address-info-img-label {
  margin-top: 4px ;
}
.address-info-gap {
  padding: 16px 2%;
}
.address-info-block {
  text-align: start;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.address-info-label {
  margin-bottom: 12px;
}
.address-info-value {
  font-size: 0.9rem;
}
</style>
