<template>
  <div class="time-info card-bg box-shadow border-radius-square-soft">
    <div class="card-title card-block card-title-height">
      <span class="material-icons card-title-img">calendar_today</span>
      <span>{{ years[0] }}-{{ years[1] }}</span>
    </div>
    <div class="time-info-content card-content-height">
      <div class="time-info-slider time-info-slider-gap">
        <span class="time-info-slider-label">{{ slider.max }}</span>
        <vue-slider
          v-model="years"
          direction="btt"
          class="time-info-slider-main custom-slider"
          style="display: inline-block; height: 100%;"
          :railStyle="{'background-color': '#e6e6e6'}"
          :width='8'
          :containeed='true'
          :min="slider.min"
          :max="slider.max"
        />
        <span class="time-info-slider-label">{{ slider.min }}</span>
      </div>
      <div class="time-info-main time-info-main-gap" style="text-align: start;">
        <div class="time-info-main-box" v-bind:class="{ active: isCompare }">
          <div class="time-info-main-text">
            <drop-down title="空拍影像" initial>
              <drop-down-item
                v-for="(item, index) in imgSorts"
                :key="index"
                @click.native="setImgSort(item.value)"
              >
                {{ item.text }}
              </drop-down-item>
            </drop-down>
          </div>
          <layer-box class="time-info-main-data" :isOpen='layers.length>0' :isCompare='isCompare'>
            <layer-box-item
              v-for="(item, index) in layers"
              :key="index"
              :item="item"
              :overlaies='overlaies'
              :overlaiesCompare='overlaiesCompare'
              :onCompare='onCompare'
              @open-layer="showLayer"
              @close-layer="closeLayer"
              @open-compare-layer="showCompareLayer"
              @close-compare-layer="closeCompareLayer"
            >
            </layer-box-item>
          </layer-box>
        </div>
        <div class="time-inf-main-line" v-if="!isCompare"></div>
        <div class="time-info-main-box" v-if="!isCompare">
          <div class="time-info-main-label clearfix">
            <drop-down :title="photoType" initial>
              <drop-down-item
                v-for="(item, index) in photoSorts"
                :key="index"
                @click.native="setPhoto(item)"
              >
                {{ item.text }}
              </drop-down-item>
            </drop-down>
            <span class="time-info-main-label-download">下載清單</span>
          </div>
          <photo-box class="time-info-main-data" :isOpen='photos.length>0'>
            <photo-box-item
              v-for="(item, index) in photos"
              :key="index"
            >
              <img
                :src="item.url"
                class="border-radius-square"
                @click="showMarker(item)"
              >
            </photo-box-item>
          </photo-box>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import { DropDown, DropDownItem } from '@/components/DropDown/index';
import { PhotoBox, PhotoBoxItem } from '@/components/PhotoBox/index';
import { LayerBox, LayerBoxItem } from '@/components/LayerBox/index';

export default {
  name: 'TimeInfo',
  components: {
    VueSlider,
    DropDown,
    DropDownItem,
    PhotoBox,
    PhotoBoxItem,
    LayerBox,
    LayerBoxItem,
  },
  props: [
    'photos',
    'layers',
    'overlaies',
    'overlaiesCompare',
    'onCompare',
    'isCompare',
  ],
  data() {
    return {
      photoType: '環境照片',
      isExtend: false,
      imgSorts: [
        { text: '空拍影像', value: 'sky' },
        { text: '太空影像', value: 'space' },
      ],
      photoSorts: [
        { text: '環境照片', value: 'normal' },
        { text: '社群攀爬', value: 'social' },
      ],
      years: [2000, 2020],
      slider: {
        min: 2000,
        max: 2020,
      },
    };
  },
  methods: {
    showMarker(data) {
      this.$emit('show-marker-data', data);
    },
    showLayer(data) {
      this.$emit('show-layer-data', data);
    },
    closeLayer(data) {
      this.$emit('close-layer-data', data);
    },
    showCompareLayer(data) {
      this.$emit('show-compare-layer-data', data);
    },
    closeCompareLayer(data) {
      this.$emit('close-compare-layer-data', data);
    },
    setPhoto(data) {
      this.photoType = data.text;
      this.$emit('set-marker-data', data.value);
    },
  },
};
</script>

<style lang='scss'>
$themeColor: #F9D772;
@import '~vue-slider-component/lib/theme/antd.scss';
.time-info {
  width: 100%;
  height: 100%;
  z-index: 3;
}
.time-info-title {
  background-color: #F9D772;
  display: flex;
  justify-content: center;
  align-items: center;;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.time-info-content {
  width: 100%;
  height: 100%;
  display: flex;
}
.time-info-slider {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.time-info-slider-label {
  font-size: .9rem;
}
.time-info-slider-main {
  margin: 30% 0;
}
.time-info-main {
  flex: 6;
}
.time-info-slider-gap {
  padding: 5% 3% 5% 6%;
}
.time-info-main-gap {
  padding: 0 8% 0 3%;
}
.time-info-main-box {
  height: 49%;
  padding: 6% 0;
}
.time-info-main-box.active {
  height: 100%;
  max-height: 540px;
}
.time-info-main-nondata {
  width: 70%;
  margin: 0 auto;
}
.time-info-main-nondata-text {
  font-size: 0.9rem;
  text-align: center;
  margin: 2% 0 2%;
  color: #9E9E9E;
}
.time-inf-main-line {
  width: 100%;
  height: 1px;
  background-color: #C4C4C4;
}
.time-info-main-text {
  height: 22px;
}
.time-info-main-data {
  height: calc(100% - 22px);
}
.time-info-main-dataimg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.time-info-main-data-gap {
  padding: 4% 0 0;
}
.time-info-main-label-env {
  float: left;
}
.time-info-main-label-download {
  float: right;
}
</style>
