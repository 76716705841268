<template>
  <header class="header flex align-center justify-between">
    <div class="flex align-center">
      <div class="logo">
        <img :src="require('@/assets/logo.png')">
      </div>
      <drop-down
        :title="mapType.text"
        :btn-class="getClass(mapType.value, maps, 'nav-btn-on')"
        class="nav-gap"
      >
        <drop-down-item
          v-for="(item, index) in maps"
          :key="index"
          @click.native="setMap(item)"
        >
          {{ item.text }}
        </drop-down-item>
      </drop-down>
      <drop-down
        :title="modeType.text"
        :btn-class="getClass(modeType.value, modes, 'nav-btn-on')"
        class="nav-gap"
      >
        <drop-down-item
          v-for="(item, index) in modes"
          :key="index"
          @click.native="setMode(item)"
        >
          {{ item.text }}
        </drop-down-item>
      </drop-down>
      <drop-down
        :title="toolType.text"
        :btn-class="getClass(enabledFunc.value, tools, 'nav-btn-on')"
        class="nav-gap"
      >
        <drop-down-item
          v-for="(item, index) in tools"
          :key="index"
          @click.native="setTool(item)"
        >
          {{ item.text }}
        </drop-down-item>
      </drop-down>
      <button
        class="nav-btn nav-gap border-radius-oval box-shadow"
        :class="getClass(enabledFunc.value, [{ text:'地點資訊', value: 'locationInfo'}], 'nav-btn-on')"
        @click="setEnabledFunc({ text:'地點資訊', value: 'locationInfo'})"
      >
        地點資訊
      </button>
      <button
        class="nav-btn nav-gap border-radius-oval box-shadow"
        :class="getClass(enabledFunc.value, [{ text:'衛星圖資下載', value: 'satelliteImgInfo'}], 'nav-btn-on')"
        @click="setEnabledFunc({ text:'衛星圖資下載', value: 'satelliteImgInfo'})"
      >
        衛星圖資下載
      </button>
    </div>
    <div>
      <search-drop-down class="nav-gap nav-search" ref="dropdown">
        <drop-down-item
          v-for="(item, index) in markers"
          :key="index"
          @click.native="setLocation(index)"
        >
          {{ item.value + "筆  " + item.name }}
        </drop-down-item>
      </search-drop-down>
      <!-- <button
        class="nav-gap nav-switch box-shadow"
        v-bind:class="{ active: isSynchronize }"
        @click="toggleSwitch()"
        v-if='isCompare'
        >
        <span>畫面連動</span>
        <span class="material-icons" v-if='isSynchronize'>
          toggle_on
        </span>
        <span class="material-icons" v-else >
          toggle_off
        </span>
      </button> -->
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { DropDown, SearchDropDown, DropDownItem } from '@/components/DropDown/index';
import { getClass } from '@/utils/styles';

export default {
  name: 'Header',
  components: {
    DropDown,
    SearchDropDown,
    DropDownItem,
  },
  props: [
    'isSynchronize',
    'isCompare',
  ],
  data() {
    return {
      mapType: { text: '地圖', value: 'normal' },
      modeType: { text: '套疊模式', value: 'normal' },
      toolType: { text: '測量工具', value: 'normal' },
      maps: [
        { text: '地圖', value: 'normal' },
        { text: '衛星圖', value: 'satellite' },
      ],
      modes: [
        { text: '套疊模式', value: 'normal' },
        { text: '並列模式', value: 'side' },
        { text: '重疊模式', value: 'overlay' },
      ],
      tools: [
        { text: '地形高程', value: 'elevation' },
        { text: '距離測量', value: 'distance' },
        { text: '面積測量', value: 'area' },
      ],
      isOpen: false,
      markers: [
        {
          position: { lat: 25.000000, lng: 121.650000, height: 42.51 },
          name: '華梵大學',
          address: '石碇區華梵路1號',
          value: '16',
        },
        {
          position: { lat: 25.000000, lng: 121.680000, height: 40.51 },
          name: '華梵大學東側',
          address: '石碇區華梵路3號',
          value: '10',
        },
        {
          position: { lat: 25.050000, lng: 121.680000, height: 41.51 },
          name: '華梵大學北側',
          address: '石碇區華梵路13號',
          value: '14',
        },
      ],
      photoMarkers: [
        {
          position: { lat: 25.040000, lng: 121.670000 },
          name: '華梵大學.jpg',
          url: require('@/assets/img/photo/pic1.png'),
          address: '石碇區華梵路1號',
          time: '2020-08-13 13:34:22',
          size: '4000 x 3200',
          dpi: '72 x 72',
          machine: 'DJI Phantom 3 Professional',
          event: '86豪雨',
        },
        {
          position: { lat: 25.012000, lng: 121.690000 },
          name: '華梵大學南部.jpg',
          url: require('@/assets/img/photo/pic2.png'),
          address: '石碇區華梵路1號',
          time: '2020-08-13 13:36:22',
          size: '4000 x 3200',
          dpi: '72 x 72',
          machine: 'DJI Phantom 3 Professional',
          event: '86豪雨',
        },
        {
          position: { lat: 25.020000, lng: 121.610000 },
          name: '華梵大學.jpg',
          url: require('@/assets/img/photo/pic3.png'),
          address: '石碇區華梵路1號',
          time: '2020-08-13 13:39:22',
          size: '4000 x 3200',
          dpi: '72 x 72',
          machine: 'DJI Phantom 3 Professional',
          event: '86豪雨',
        },
        {
          position: { lat: 25.030000, lng: 121.630000 },
          name: '華梵大學南部.jpg',
          url: require('@/assets/img/photo/pic4.png'),
          address: '石碇區華梵路1號',
          time: '2020-08-13 13:42:22',
          size: '4000 x 3200',
          dpi: '72 x 72',
          machine: 'DJI Phantom 3 Professional',
          event: '86豪雨',
        },
      ],
      socialPhotoMarkers: [
        {
          position: { lat: 25.045000, lng: 121.590000 },
          name: '中研院溢堤.jpg',
          url: 'https://uon4wsbc.strangled.net/EDXL/Incident/GetImage?id=45db0mtst0u.jpg',
          address: '台北市中正區',
          time: '2021-06-04 14:28:00',
          size: '',
          dpi: '',
          machine: '',
          event: '中研院',
        },
        {
          position: { lat: 25.012000, lng: 121.690000 },
          name: '富陽街72巷淹水.jpg',
          url: 'https://uon4wsbc.strangled.net/EDXL/Incident/GetImage?id=ofkc0ylthib.jpg',
          address: '台北市信義區',
          time: '2021/06/04 14:22:12',
          size: '',
          dpi: '',
          machine: '',
          event: '86豪雨',
        },
        {
          position: { lat: 25.025000, lng: 121.601000 },
          name: '再興中學門口淹水.jpg',
          url: 'https://uon4wsbc.strangled.net/EDXL/Incident/GetImage?id=pm4tcbdfov3.jpg',
          address: '台北市文山區再興路三段',
          time: '2020-08-13 13:39:22',
          size: '',
          dpi: '',
          machine: '',
          event: '86豪雨',
        },
        {
          position: { lat: 25.030000, lng: 121.630000 },
          name: '再興中學門口淹水.jpg',
          url: 'https://uon4wsbc.strangled.net/EDXL/Incident/GetImage?id=bfuh3cjnb0e.jpg',
          address: '台北市文山區再興路三段',
          time: '2021/06/04 13:59:00',
          size: '',
          dpi: '',
          machine: '',
          event: '再興中學',
        },
      ],
      layers: [
        {
          name: '20200801華梵大學',
          time: '2020-08-13 13:34:22',
          tag: '豪雨、崩塌、土石流',
          event: '86豪雨',
          url: '/layers/test2/',
        },
        {
          name: '20200901華梵大學',
          time: '2020-09-01 13:34:22',
          tag: '豪雨、崩塌、土石流',
          event: '86豪雨',
          url: '/layers/test3/',
        },
        {
          name: '20210301華梵大學',
          time: '2021-03-01 13:34:22',
          tag: '災後復原、環境調查',
          event: '86豪雨',
          url: '/layers/test4/',
        },
        {
          name: '20210501華梵大學',
          time: '2021-05-01 13:34:22',
          tag: '災後復原、環境調查',
          event: '86豪雨',
          url: '/layers/test5/',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['google', 'drawingManager', 'enabledFunc']),
  },
  methods: {
    getClass,
    setMap(data) {
      this.mapType = data;
    },
    setMode(data) {
      this.modeType = data;
      this.$emit('set-mode', this.modeType);
      this.$store.dispatch('app/setMode', data);
    },
    setTool(data) {
      const toolTypeValue = this.toolType.value;
      if (toolTypeValue !== data.value) {
        this.toolType = data;
        this.$store.dispatch('measureTool/clearAllOverlays');
        this.$store.dispatch('measureTool/clearFocusedOverlay');
        this.$store.dispatch('app/setEnabledFunc', data);
        switch (data.value) {
          case 'elevation':
            this.$store.dispatch('measureTool/setUnit', { text: '公里', value: 'kilometer' });
            this.drawingManager.setDrawingMode(this.google.maps.drawing.OverlayType.POLYLINE);
            break;
          case 'distance':
            this.$store.dispatch('measureTool/setUnit', { text: '公里', value: 'kilometer' });
            this.drawingManager.setDrawingMode(this.google.maps.drawing.OverlayType.POLYLINE);
            break;
          case 'area':
            this.$store.dispatch('measureTool/setUnit', { text: '平方公尺', value: 'square meter' });
            this.drawingManager.setDrawingMode(this.google.maps.drawing.OverlayType.POLYGON);
            break;
          default:
            this.drawingManager.setDrawingMode(this.google.maps.drawing.OverlayType.POLYLINE);
        }
      }
    },
    setLocation(id) {
      this.$refs.dropdown.confirmSearch(this.markers[id].name);
      this.$refs.dropdown.closeWindow();
      this.$emit('get-marker-data', {
        marker: this.markers[id],
        photoMarkers: this.photoMarkers,
        socialPhotoMarkers: this.socialPhotoMarkers,
        layers: this.layers,
      });
    },
    toggleSwitch() {
      this.$emit('toggle-synchronize');
    },
    setEnabledFunc(funcObj) {
      if (funcObj.value === this.enabledFunc.value) return;
      this.$store.dispatch('app/setEnabledFunc', funcObj);
      this.$store.dispatch('measureTool/clearAllOverlays');
      this.$store.dispatch('measureTool/clearFocusedOverlay');
      this.$store.dispatch('measureTool/setUnit', {});
      this.drawingManager.setDrawingMode(null);
    },
  },
  watch: {
    // eslint-disable-next-line
    'enabledFunc.value'(newValue) {
      const map = ['elevation', 'distance', 'area'];
      if (map.includes(newValue) === false) {
        this.$store.dispatch('measureTool/clearAllOverlays');
        this.toolType = { text: '測量工具', value: 'normal' };
      }
    },
  },
};
</script>

<style lang='scss'>
.header {
  width: 100%;
  height: 80px;
}
.logo {
  width: 90px;
  height: 50px;
  margin-top: 10px;
}
.nav-gap {
  margin: 0 8px;
}
.nav-switch{
  // position: absolute;
  // right:0;
  // top:70px;
  // display: block;
  // background-color: #F9D772;
  // padding: 0rem 1.4rem;
  // font-size: 1rem;
  // cursor: pointer;
  // border-radius: 30px;
  // font-size: 1rem;
  // cursor:pointer;
  // border:0;
  // color:#333;
  min-width: 50px;
  padding: 0.6rem 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  background-color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  font-weight: 900;
}
.nav-switch span{
  display: inline-block;
  vertical-align: middle;
  transition: all .5s ease-out;
}
.nav-switch .material-icons{
  color:#c7ab55;
}
.nav-switch.active .material-icons{
  color:#333;
}
.nav-switch .material-icons{
  font-size: 36px;
}
.nav-search {
  float: right;
  text-align: left;
}
.material-icons + .search-input{
  border:0 !important;
  width:0;
  //display:none;
  transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
  float:left;
}
.material-icons.active + .search-input{
  width:160px;
  //display:block;
}
</style>
