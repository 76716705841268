<template>
  <div class="home">
    <GmapMap
      id="map-canvas-right"
      :center="{ lat: mapCenter.lat, lng: mapCenter.lng }"
      :zoom="zoom"
      :options="{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false,
        clickableIcons: false,
        disableDoubleClickZoom: true,
        minZoom: 8,
        styles: mapStyle,
      }"
      class="w-100 h-100"
      style="position: fixed; left:0; top:0; z-index:1;"
      :position="google && new google.maps.LatLng(1.38, 103.8)"
      ref="rightMap"
    >
      <GmapMarker
        v-if="selectedMarker.position"
        :position="selectedMarker.position"
        :clickable="false"
        :draggable="false"
        :icon="{ url: require('@/assets/img/spot.png')}"
      />
    </GmapMap>
    <ResizeMap :isCompare='isCompare' ref="compareRefMap">
      <GmapMap
        id="map-canvas-left"
        :class="{ active: isSide }"
        :center="{ lat: mapCenter.lat, lng: mapCenter.lng }"
        :zoom="zoom"
        :options="{
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUI: false,
          clickableIcons: false,
          disableDoubleClickZoom: true,
          minZoom: 8,
          styles: mapStyle,
        }"
        class="w-100 h-100"
        style="position: absolute; left:0; top:0;"
        :position="google && new google.maps.LatLng(1.38, 103.8)"
        ref="leftMap"
      >
        <gmap-info-window
          v-if="!isCompare"
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen=false"
        >
          <div v-html="infoContent" @click="showInfoWindowContent(null)"></div>
        </gmap-info-window>
        <div v-if="!isCompare">
          <GmapMarker
            :key="photoindex"
            v-for="(photomarker, photoindex) in photoMarkers"
            :position="photomarker.position"
            :clickable="true"
            :draggable="false"
            @click="toggleInfoWindow(photomarker, photoindex)"
            :icon="{ url: require('@/assets/img/photomarker.png')}"
          />
        </div>
        <GmapMarker
          v-if="selectedMarker.position"
          :position="selectedMarker.position"
          :clickable="false"
          :draggable="false"
          :icon="{ url: require('@/assets/img/spot.png')}"
        />
        <GmapCircle
          v-if="!isCompare && selectedMarker.position"
          :center="selectedMarker.position"
          :radius="10000"
          :visible="true"
          :options="{fillColor:'yellow',fillOpacity:.1,strokeOpacity:0,clickable:false}"
        />
        <!-- 測量工具(線&面積&infoWindow) -->
        <div>
          <div v-for="(item, index) in measureOverlays" :key="index">
            <gmap-polyline
              v-if="item.isShow === true && item.type === 'polyline'"
              :path="item.path"
              :options="{ strokeColor: '#ffcc00', strokeWeight: 4 }"
            />
            <gmap-polygon
              v-if="item.isShow === true && item.type === 'polygon'"
              :path="item.path"
              :options="{ strokeColor: '#a1a1d3' }"
            />
          </div>
          <gmap-info-window
            v-if="measureInfoWindowData"
            :options="{
              pixelOffset: {
                width: 0,
                height: -10,
              },
            }"
            :position="measureInfoWindowData.position"
            :opened="measureInfoWindowData.isOpened"
            @closeclick="closeMeasureToolInfoWindow"
          >
            <div>
              <span class="material-icons info-timeline">timeline</span>
              <div>
                <span class="block" style="margin:4px 0;">此段距離為</span>
                <span class="block" style="margin:4px 0; font-weight: 700">
                  {{ getUnitValue() }}
                </span>
              </div>
            </div>
          </gmap-info-window>
        </div>
        <div v-if="enabledFunc.value === 'locationInfo' && locationInfoData.position">
          <gmap-info-window
            :options="{
              pixelOffset: {
                width: 0,
                height: -10,
              },
            }"
            :position="locationInfoData.position"
            :opened="locationInfoData.isOpened"
            @closeclick="closeLocationInfoWindow"
          >
            <div style="text-align: left;" class="location-info">
              <ul>
                <li>地址: <span>{{ locationInfoData.address }}</span></li>
                <li>經度: <span>{{ locationInfoData.position.lng }}</span></li>
                <li>緯度: <span>{{ locationInfoData.position.lat }}</span></li>
                <li>標高: <span>{{ getTwoDecimalPlaces(locationInfoData.elevation) }}m</span></li>
              </ul>
            </div>
          </gmap-info-window>
        </div>
      </GmapMap>
    </ResizeMap>
    <div class="main">
      <div class="main-mode pointer-event-none">
        <div class="main-mode-wrapper">
          <Header
            class="header-gap pointer-event-all"
            :isSynchronize="isSynchronize"
            :isCompare="isCompare"
            @get-marker-data="getMarkers"
            @set-mode="setMode"
            @toggle-synchronize="toggleSynchronize"
          />
          <support-tool class="header-gap support-tool" />
          <AddressInfo :labels='locationDatas' class="pointer-event-all" />
          <div
            class="elevation-chart border-radius-square-soft pointer-event-all"
            v-if="enabledFunc.value === 'elevation' && chartdata.labels.length > 0"
          >
            <BarChart
              :chartdata="chartdata"
              :options="{
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                  display: 'none',
                },
                scales: {
                  yAxes: [{
                    display: true,
                    scaleLabel: {
                      display: true,
                      labelString: '高程 (m)',
                    },
                  }]
                },
              }"
              :style="{ height: '100%', position: 'relative' }"
            />
          </div>
        </div>
      </div>
      <div class="main-side">
        <div class="main-side-wrapper">
          <div class="w-100 h-100 relative">
            <div class="main-side-container flex flex-col">
              <ImgOverlay
                :overlaies='overlaies'
                :overlaiesCompare='overlaiesCompare'
                :isCompare='isCompare'
                :onCompare='onCompare'
                @show-layer-data="openOverlay"
                @hide-layer-data="hideOverlay"
                @close-layer-data="closeOverlay"
                @sort-layer-data="sortOverlay"
                @show-compare-layer-data="openCompareOverlay"
                @hide-compare-layer-data="hideCompareOverlay"
                @close-compare-layer-data="closeCompareOverlay"
                @sort-compare-layer-data="sortCompareOverlay"
                @on-compare="setCompare"
              />
              <TimeInfo
                style="flex: 1;"
                class="main-side-block"
                :photos='photoMarkers'
                :layers='mapLayers'
                :overlaies='overlaies'
                :overlaiesCompare='overlaiesCompare'
                :onCompare='onCompare'
                :isCompare='isCompare'
                @set-marker-data="setMarkers"
                @show-marker-data="showInfoWindowContent"
                @show-layer-data="openOverlay"
                @close-layer-data="closeOverlay"
                @show-compare-layer-data="openCompareOverlay"
                @close-compare-layer-data="closeCompareOverlay"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading ref="loading" />
    <PhotoInfo :datas='photoInfos' ref="photoinfo" />
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';
import { mapGetters } from 'vuex';
import { mapStyle } from '@/config/gmap';
import { getDistanceFromLatLng } from '@/utils/map-calculation';
import { BarChart } from '@/components/Chart';
import { generateUuid, getTwoDecimalPlaces } from '@/utils/format';
import Header from './components/Header.vue';
import SupportTool from './components/SupportTool.vue';
import AddressInfo from './components/AddressInfo.vue';
import ImgOverlay from './components/ImgOverlay.vue';
import TimeInfo from './components/TimeInfo.vue';
import Loading from './components/Loading.vue';
import PhotoInfo from './components/PhotoInfo.vue';
import ResizeMap from './components/ResizeMap.vue';

export default {
  name: 'Home',
  components: {
    Header,
    SupportTool,
    AddressInfo,
    ImgOverlay,
    TimeInfo,
    Loading,
    PhotoInfo,
    ResizeMap,
    BarChart,
  },
  data() {
    return {
      mapLeft: null,
      mapRight: null,
      mapCenter: { lat: 23.7723, lng: 122.0412 },
      zoom: 8,
      mapStyle,
      isSynchronize: true,
      isCompare: false,
      isSide: false,
      onCompare: false,
      selectedMarker: {},
      photoMarkers: [],
      photos: [],
      socialPhotos: [],
      locationDatas: [
        { name: '地址', value: '-' },
        { name: '地點', value: '-' },
        { name: '經度', value: '-' },
        { name: '緯度', value: '-' },
        { name: '標高', value: '-' },
      ],
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      photoInfos: null,
      mapLayers: [],
      overlaies: [],
      overlaiesCompare: [],
    };
  },
  computed: {
    chartdata() {
      const emptyLabels = this.elevationPath.map(() => '');
      return {
        labels: emptyLabels,
        datasets: [
          {
            label: '高度',
            backgroundColor: '#7E7E7E',
            data: this.elevationPath,
          },
        ],
      };
    },
    ...mapGetters([
      'measureOverlays',
      'elevationPath',
      'measureInfoWindowData',
      'measureUnit',
      'enabledFunc',
      'locationInfoData',
    ]),
    google: gmapApi,
  },
  methods: {
    getMarkers(data) {
      this.toggleLoading();
      console.log(data);
      setTimeout(() => {
        this.selectedMarker = data.marker;
        this.mapCenter = { lat: data.marker.position.lat, lng: data.marker.position.lng };
        this.zoom = 12;
        this.locationDatas = [
          { name: '地址', value: data.marker.name },
          { name: '地點', value: data.marker.address },
          { name: '經度', value: data.marker.position.lng },
          { name: '緯度', value: data.marker.position.lat },
          { name: '標高', value: data.marker.position.height },
        ];
        this.photoMarkers = data.photoMarkers;
        this.photos = data.photoMarkers;
        this.socialPhotos = data.socialPhotoMarkers;
        this.mapLayers = data.layers;
        this.toggleLoading();
      }, 2000);
      // const boundary = new google.maps.LatLngBounds();
      // boundary.extend(this.selectedMarker.position);
    },
    setMarkers(data) {
      this.toggleLoading();
      console.log(data);
      setTimeout(() => {
        if (data === 'normal') {
          this.photoMarkers = this.photos;
        } else {
          this.photoMarkers = this.socialPhotos;
        }
        this.toggleLoading();
      }, 2000);
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoContent = this.getInfoWindowContent(marker);
      this.photoInfos = marker;
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    getInfoWindowContent(marker) {
      return (`<div class="card">
        <h3>${marker.name}</h3>
        <div class="card-image">
          <img src="${marker.url}" alt="${marker.name}">
        </div>
        <div class="card-content">
          <div class="content">
            緯度:${marker.position.lat}<br>
            經度:${marker.position.lng}
          </div>
        </div>
      </div>`);
    },
    showInfoWindowContent(data) {
      if (data !== null) {
        this.photoInfos = data;
      }
      this.$refs.photoinfo.togglePhotoInfo();
    },
    closeInfoWindow() {
      this.infoWinOpen = false;
    },
    toggleLoading() {
      this.$refs.loading.toggleLoading();
    },
    toggleSynchronize() {
      this.isSynchronize = !this.isSynchronize;
      this.initializeCompareMap();
    },
    openOverlay(data) {
      if (this.overlaies.indexOf(data) === -1) {
        this.overlaies.push(data);
      }
      const pix4tiler = this.createOverlay(data.url, '100');
      this.mapLeft.overlayMapTypes.insertAt(this.overlaies.indexOf(data), pix4tiler);
    },
    hideOverlay(data) {
      this.mapLeft.overlayMapTypes.removeAt(this.overlaies.indexOf(data));
    },
    closeOverlay(data) {
      this.mapLeft.overlayMapTypes.removeAt(this.overlaies.indexOf(data));
      this.overlaies.splice(this.overlaies.indexOf(data), 1);
    },
    sortOverlay() {
      this.mapLeft.overlayMapTypes.clear();
      this.overlaies.forEach((value, index) => {
        const pix4tiler = this.createOverlay(value.url, '100');
        this.mapLeft.overlayMapTypes.insertAt(index, pix4tiler);
      });
    },
    openCompareOverlay(data) {
      if (this.overlaiesCompare.indexOf(data) === -1) {
        this.overlaiesCompare.push(data);
      }
      const pix4tiler = this.createOverlay(data.url, '100');
      this.mapRight.overlayMapTypes.insertAt(this.overlaiesCompare.indexOf(data), pix4tiler);
    },
    hideCompareOverlay(data) {
      this.mapRight.overlayMapTypes.removeAt(this.overlaiesCompare.indexOf(data));
    },
    closeCompareOverlay(data) {
      this.mapRight.overlayMapTypes.removeAt(this.overlaiesCompare.indexOf(data));
      this.overlaiesCompare.splice(this.overlaiesCompare.indexOf(data), 1);
    },
    sortCompareOverlay() {
      this.mapRight.overlayMapTypes.clear();
      this.overlaiesCompare.forEach((value, index) => {
        const pix4tiler = this.createOverlay(value.url, '100');
        this.mapRight.overlayMapTypes.insertAt(index, pix4tiler);
      });
    },
    createOverlay(url, percent) {
      const mapMinZoom = 10;
      const mapMaxZoom = 20;
      const pix4tiler = new this.google.maps.ImageMapType({
        getTileUrl: (coord, zoom) => {
          if (zoom >= mapMinZoom && zoom <= mapMaxZoom) {
            const coordx = coord.x;
            const coordy = (2 ** zoom) - coord.y - 1;
            const link = `${url}${zoom}/${coordx}/${coordy}.png`;
            return link;
          }
          return null;
        },
        tileSize: new this.google.maps.Size(256, 256),
        isPng: true,
        opacity: parseFloat(percent) / 100.0,
      });
      return pix4tiler;
    },
    setMode(data) {
      if (data.value === 'normal') {
        this.isCompare = false;
        this.$refs.compareRefMap.closeCompare();
      } else {
        this.isCompare = true;
        if (data.value === 'side') {
          this.isSide = true;
        } else {
          this.isSide = false;
        }
        this.initializeCompareMap(data.value);
        this.$refs.compareRefMap.openCompare();
      }
    },
    initializeCompareMap() {
      this.google.maps.event.clearListeners(this.mapLeft, 'drag');
      this.google.maps.event.clearListeners(this.mapRight, 'drag');
      this.google.maps.event.clearListeners(this.mapLeft, 'dragend');
      this.google.maps.event.clearListeners(this.mapRight, 'dragend');
      // document.getElementById('map-canvas-left').removeEventListener('mousewheel');
      // document.getElementById('map-canvas-right').removeEventListener('mousewheel');
      if (this.isSynchronize) {
        this.google.maps.event.addListener(this.mapLeft, 'drag', () => {
          this.mapRight.setZoom(this.mapLeft.getZoom());
          this.mapRight.setCenter(this.mapLeft.getCenter());
        });
        this.google.maps.event.addListener(this.mapRight, 'drag', () => {
          this.mapLeft.setZoom(this.mapRight.getZoom());
          this.mapLeft.setCenter(this.mapRight.getCenter());
        });
        this.google.maps.event.addListener(this.mapLeft, 'dragend', () => {
          this.mapRight.setZoom(this.mapLeft.getZoom());
          this.mapRight.setCenter(this.mapLeft.getCenter());
        });
        this.google.maps.event.addListener(this.mapRight, 'dragend', () => {
          this.mapLeft.setZoom(this.mapRight.getZoom());
          this.mapLeft.setCenter(this.mapRight.getCenter());
        });
        document.getElementById('map-canvas-left').addEventListener('mousewheel', () => {
          setTimeout(() => {
            this.mapRight.setZoom(this.mapLeft.getZoom());
            this.mapRight.setCenter(this.mapLeft.getCenter());
          }, 30);
        }, true);
        document.getElementById('map-canvas-right').addEventListener('mousewheel', () => {
          setTimeout(() => {
            this.mapLeft.setZoom(this.mapRight.getZoom());
            this.mapLeft.setCenter(this.mapRight.getCenter());
          }, 30);
        }, true);
      }
    },
    setCompare(data) {
      this.onCompare = data;
    },
    setDrawingManager() {
      const drawingManager = new this.google.maps.drawing.DrawingManager({
        drawingControl: false,
      });
      const elevator = new this.google.maps.ElevationService();
      drawingManager.setMap(this.$refs.leftMap.$mapObject);
      this.$store.dispatch('gmap/setGoogleApi', this.google);
      this.$store.dispatch('gmap/setDrawingManager', drawingManager);
      this.google.maps.event.addListener(drawingManager, 'overlaycomplete', async (event) => {
        const arrData = event.overlay.getPath().getArray().map((coord) => ({
          lat: coord.lat(),
          lng: coord.lng(),
        }));
        let unitValue = 0;
        let unit = '';
        let elevationPath = [];
        switch (event.type) {
          case 'polyline':
            // eslint-disable-next-line
            arrData.forEach((point, pointIndex) => {
              if (pointIndex === arrData.length - 1) return 0;
              const distance = getDistanceFromLatLng(point, arrData[pointIndex + 1]);
              unitValue += distance;
            });
            unit = '公里';
            if (this.enabledFunc.value === 'elevation') {
              const path = await this.getElevationArr(elevator, arrData);
              elevationPath = path.map((point) => point.elevation);
            }
            break;
          case 'polygon':
            unitValue = this.google.maps.geometry.spherical.computeArea(
              event.overlay.getPath().getArray(),
            );
            unit = '平方公尺';
            break;
          default:
            break;
        }
        const overlayData = {
          uuid: generateUuid(),
          arrData,
          type: event.type,
          unit,
          unitValue,
          elevationPath,
        };
        event.overlay.setMap(null);
        this.$store.dispatch('measureTool/addOverlay', overlayData);
        this.$store.dispatch('measureTool/setFocusedOverlay', overlayData);
      });
    },
    async getElevationArr(elevator, path) {
      const { results } = await elevator.getElevationAlongPath({
        path,
        samples: 256,
      });
      return results;
    },
    closeMeasureToolInfoWindow() {
      this.$store.dispatch('measureTool/setInfoWindowStatus', false);
    },
    closeLocationInfoWindow() {
      this.$store.dispatch('locationInfo/setInfoWindowStatus', false);
    },
    getUnitValue() {
      const formatValue = getTwoDecimalPlaces(this.measureInfoWindowData.unitValue * this.measureUnit.times);
      return `${formatValue} ${this.measureUnit.text}`;
    },
    getTwoDecimalPlaces,
  },
  mounted() {
    this.$refs.leftMap.$mapPromise.then((map) => {
      this.mapLeft = map;
      this.setDrawingManager();
    });
    this.$refs.rightMap.$mapPromise.then((map) => {
      this.mapRight = map;
    });
  },
  watch: {
    measureInfoWindowData: {
      handler(newData, oldData) {
        if (newData == null) return;
        // 第一次畫線newData有值 oldData是null
        if ((newData.uuid && !oldData) || (newData.uuid !== oldData.uuid)) {
          const { lat, lng } = newData.position;
          this.mapLeft.panTo({
            lat,
            lng,
          });
        }
      },
      deep: true,
    },
    enabledFunc: {
      async handler(newFunc) {
        const { value } = newFunc;
        if (value === 'locationInfo') {
          const geocoder = new this.google.maps.Geocoder();
          const elevator = new this.google.maps.ElevationService();
          this.google.maps.event.addListener(this.mapLeft, 'dblclick', async (event) => {
            const latlng = event.latLng.toJSON();
            const elevationRes = (await elevator.getElevationForLocations({ locations: [latlng] })).results;
            const geocodeRes = (await geocoder.geocode({ latLng: event.latLng })).results;
            const address = geocodeRes[0].formatted_address;
            this.$store.dispatch('locationInfo/setLocationInfo', {
              address,
              position: latlng,
              elevation: elevationRes[0].elevation,
              isOpened: true,
            });
          });
        } else {
          this.google.maps.event.clearListeners(this.mapLeft, 'dblclick');
          this.$store.dispatch('locationInfo/clearLocationInfo');
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang='scss'>
.home {
  width: 100%;
  height: 100vh;
  position: relative;
}
#map-canvas-left.active{
  right:0 !important;
  left:auto !important;
}
.container-map {
  width: 95%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  border: 1px solid transparent;
}
.main {
  display: flex;
  height: 100%;
}
.main-mode {
  flex: 5;
  height: 100%;
  position: relative;
}
.main-mode-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.main-side {
  flex: 2;
  height: 100%;
  position: relative;
}
.main-side-wrapper {
  width: 100%;
  height: 100%;
  padding: 5% 9%;
}
.main-side-container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}
.main-side-block {
  margin-top: 5%;
}
.card-image img {
  width:200px;
  height: auto;
  border-radius: 4px;
  cursor:pointer;
}
.gm-style .gm-style-iw-c {
  padding: 16px;
}
.card h3 {
  margin-bottom: 5px;
}
.card-content .content{
  text-align: left;
  margin-top: 5px;
}
.support-tool {
  margin-top: 1%;
}
.elevation-chart {
  width: 96%;
  height: 20%;
  position: absolute;
  bottom: 3%;
  left: 51.5%;
  transform: translateX(-50%);
  z-index: 3;
  padding: 12px 12px 0;
  background-color: #fff;
}
.home .info-timeline {
  font-size: 32px ;
}
.location-info {
  li {
    margin: 2px 0;
  }
}
</style>
