const gmap = {
  namespaced: true,
  state: {
    google: null,
    drawingManager: null,
  },
  mutations: {
    SET_GOOGLE_API(state, googleApi) {
      state.google = googleApi;
    },
    SET_DRAWING_MANAGER(state, manager) {
      state.drawingManager = manager;
    },
  },
  actions: {
    setGoogleApi({ commit }, googleApi) {
      commit('SET_GOOGLE_API', googleApi);
    },
    setDrawingManager({ commit }, manager) {
      commit('SET_DRAWING_MANAGER', manager);
    },
  },
};

export default gmap;
