<template>
  <li class="dropdown-item border-radius-square ">
    <slot></slot>
  </li>
</template>

<script>
export default {
  name: 'DropDownItem',
};
</script>

<style lang='scss'>
.dropdown-item {
  padding: .5rem;
  transition: background-color .1s ease-in-out;
  &:hover {
    background-color: #FDEEC2;
  }
}
</style>
