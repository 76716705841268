<template>
  <div class="img-overlay-content">
    <ul class="img-overlay-list">
      <slot></slot>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'OverlayBox',
  props: [
    'compareid',
    'overlaies',
    'overlaiesCompare',
    'isCompare',
  ],
};
</script>
