import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// plugins
import '@/plugins/vue2-google-map';
import '@/plugins/v-click-outside';
// css
import '@/assets/scss/main.scss';
import 'animate.css';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
