const unitMap = {
  // init polyline unit as kilometer
  // init polygon unit as square meter
  kilometer: 1,
  meter: 1000,
  'square meter': 1,
  'square kilometer': 0.000001,
  hectare: 0.0001,
};

const measureTool = {
  namespaced: true,
  state: {
    unit: {},
    measureOverlays: [],
    focusedOverlay: {
      data: null,
      elevationPath: [],
    },
  },
  mutations: {
    SET_MEASURE_UNIT(state, unitObj) {
      if (Object.keys(unitObj).length === 0) {
        state.unit = {};
      } else {
        const { text, value } = unitObj;
        state.unit = {
          text,
          value,
          times: unitMap[unitObj.value],
        };
      }
    },
    ADD_MEASURE_OVERLAY(state, overlayObj) {
      const {
        uuid, arrData, type, unit, unitValue, elevationPath,
      } = overlayObj;
      state.measureOverlays.push({
        uuid,
        path: arrData,
        type,
        isShow: true,
        unit,
        unitValue,
        elevationPath,
      });
    },
    SET_FOCUSED_OVERLAY(state, overlayObj) {
      const {
        uuid, arrData, type, unit, unitValue, elevationPath,
      } = overlayObj;
      state.focusedOverlay.data = {
        uuid,
        position: arrData[arrData.length - 1],
        type,
        unit,
        unitValue,
        isOpened: true,
      };
      state.focusedOverlay.elevationPath = elevationPath;
    },
    CLEAR_FOCUSED_OVERLAY(state) {
      state.focusedOverlay = {
        data: null,
        elevationPath: [],
      };
    },
    SET_INFO_WINDOW_STATUS(state, boolean) {
      if (
        state.focusedOverlay.data == null
        || state.focusedOverlay.data.isOpened === undefined
      ) return;
      state.focusedOverlay.data.isOpened = boolean;
    },
    UPDATE_OVERLAY_DISTANCE(state, data) {
      const { index, distance } = data;
      state.measureOverlays[index].distance = distance;
    },
    TOGGLE_SHOW_OVERLAY(state, index) {
      state.measureOverlays[index].isShow = !state.measureOverlays[index].isShow;
    },
    REMOVE_MEASURE_OVERLAY(state, index) {
      state.measureOverlays.splice(index, 1);
    },
    CLEAR_MEASURE_OVERLAY(state) {
      state.measureOverlays = [];
    },
  },
  actions: {
    setUnit({ commit }, unitObj) {
      commit('SET_MEASURE_UNIT', unitObj);
    },
    addOverlay({ commit }, overlayObj) {
      commit('ADD_MEASURE_OVERLAY', overlayObj);
    },
    setFocusedOverlay({ commit }, overlayObj) {
      commit('SET_FOCUSED_OVERLAY', overlayObj);
    },
    clearFocusedOverlay({ commit }) {
      commit('CLEAR_FOCUSED_OVERLAY');
    },
    setInfoWindowStatus({ commit }, value) {
      commit('SET_INFO_WINDOW_STATUS', value);
    },
    updateDistance({ commit }, data) {
      commit('UPDATE_OVERLAY_DISTANCE', data);
    },
    toggleShowOverlay({ commit }, index) {
      commit('TOGGLE_SHOW_OVERLAY', index);
    },
    removeOverlay({ commit }, index) {
      commit('REMOVE_MEASURE_OVERLAY', index);
      commit('SET_INFO_WINDOW_STATUS', false);
    },
    clearAllOverlays({ commit }) {
      commit('CLEAR_MEASURE_OVERLAY');
    },
  },
};

export default measureTool;
