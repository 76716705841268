<template>
  <div class="photobox-item border-radius-square">
    <span class="material-icons"
      @click="toggleCheck()"
      v-bind:class="{ active: isCheck }"
    >check_circle</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PhotoBoxItem',
  data() {
    return {
      isCheck: false,
    };
  },
  methods: {
    toggleCheck() {
      this.isCheck = !this.isCheck;
    },
  },
};
</script>

<style lang='scss'>
.photobox-item {
  position: relative;
  display: inline-block;
  width: 50%;
  height: 100px;
  padding: .5rem;
  transition: background-color .1s ease-in-out;
  &:hover {
    background-color: #FDEEC2;
  }
}
.photobox-item img {
  width: 100%;
  cursor: pointer;
}
.photobox-item .material-icons{
  position: absolute;
  top:12px;
  right:12px;
  font-size: 26px;
  color:#FFF;
  cursor: pointer;
  transition: color .5s ease-in-out;
  &:hover {
    color: #ebc655;
  }
  &.active {
    color: #ebc655;
  }
}
</style>
