<template>
  <div class="img-overlay card-bg box-shadow border-radius-square-soft">
    <div class="card-title card-block card-title-height">
      <span class="material-icons card-title-img">layers</span>
      <span>已套疊圖層</span>
    </div>
    <splitpanes class="default-theme" @resize="paneSize = $event[0].size">
      <pane
      min-size="24"
      class="left"
      v-bind:class="{ right: !isCompare, active: !onCompare }"
    >
      <h3 v-if="isCompare" @click="changeCompare(false)">左圖層</h3>
        <overlay-box
          class="img-overlay-content"
          :overlaies="overlaies"
          :overlaiesCompare="overlaiesCompare"
          :isCompare='isCompare'
          v-bind:class="{ active: isCompare }"
          @sort-layer="sortLayer"
          @sort-compare-layer="sortCompareLayer"
        >
          <draggable
            class="list-group"
            :list="overlaies"
            group="overlay"
            v-bind="dragOptions"
            @end="sortLayer()"
            v-bind:class="{ active: paneSize < 42 }"
          >
            <overlay-box-item
              v-for="(item, index) in overlaies"
              :key="index"
              :item="item"
              :overlaies="overlaies"
              @open-layer="showLayer"
              @hide-layer="hideLayer"
              @close-layer="closeLayer"
            >
            </overlay-box-item>
          </draggable>
        </overlay-box>
      </pane>
      <pane
        min-size="24"
        class="right"
        v-if="isCompare"
        v-bind:class="{ active: onCompare }"
      >
        <h3 @click="changeCompare(true)">右圖層</h3>
        <overlay-box
          class="img-overlay-content"
          :overlaies="overlaies"
          :overlaiesCompare="overlaiesCompare"
          :isCompare='isCompare'
          v-bind:class="{ active: isCompare }"
          @sort-layer="sortLayer"
          @sort-compare-layer="sortCompareLayer"
        >
          <draggable
            class="list-group"
            :list="overlaiesCompare"
            group="overlay"
            v-bind="dragOptions"
            @end="sortLayer()"
            v-bind:class="{ active: 100 - paneSize < 42 }"
          >
            <overlay-box-item
              v-for="(item, index) in overlaiesCompare"
              :key="index"
              :item="item"
              :overlaies="overlaiesCompare"
              @open-layer="showCompareLayer"
              @hide-layer="hideCompareLayer"
              @close-layer="closeCompareLayer"
            >
            </overlay-box-item>
          </draggable>
        </overlay-box>
      </pane>
    </splitpanes>
  </div>
</template>

<script>
import 'splitpanes/dist/splitpanes.css';
import { Splitpanes, Pane } from 'splitpanes';
import { OverlayBox, OverlayBoxItem } from '@/components/OverlayBox/index';
import draggable from 'vuedraggable';

export default {
  components: {
    OverlayBox,
    OverlayBoxItem,
    Splitpanes,
    Pane,
    draggable,
  },
  props: [
    'overlaies',
    'overlaiesCompare',
    'isCompare',
    'onCompare',
  ],
  data() {
    return {
      listid: 'overlaylist',
      comparelistid: 'compareoverlaylist',
      paneSize: 50,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 150,
        group: 'overlay',
        ghostClass: 'ghost',
      };
    },
  },
  name: 'ImgOverlay',
  methods: {
    showLayer(data) {
      this.$emit('show-layer-data', data);
    },
    hideLayer(data) {
      this.$emit('hide-layer-data', data);
      console.log(this.overlaies);
    },
    closeLayer(data) {
      this.$emit('close-layer-data', data);
    },
    sortLayer() {
      this.$emit('sort-layer-data');
      this.$emit('sort-compare-layer-data');
    },
    showCompareLayer(data) {
      this.$emit('show-compare-layer-data', data);
    },
    hideCompareLayer(data) {
      this.$emit('hide-compare-layer-data', data);
    },
    closeCompareLayer(data) {
      this.$emit('close-compare-layer-data', data);
    },
    sortCompareLayer() {
      this.$emit('sort-compare-layer-data');
    },
    changeCompare(data) {
      this.$emit('on-compare', data);
    },
  },
};
</script>

<style lang='scss'>
.img-overlay {
  width: 100%;
  z-index: 3;
}
.img-overlay-content .img-overlay-list {
  height: 82px;
  overflow-y: auto;
  text-align: left;
  padding:5px;
  overflow-x: hidden;
}
.img-overlay-content.active .img-overlay-list {
  height:162px;
}
.list-group:empty::after {
  content: "目前無套疊圖層";
  padding:6px;
  border-radius: 6px;
  background-color: #EEE;
}
.list-group.active:empty::after {
  content: "無套疊";
}
.img-overlay-listitem {
  text-align: start;
  display: flex;
  align-items: center;
}
.img-overlay-listitem-img {
  margin-right: 3%;
}
.img-overlay-content .img-overlay-listitem {
  line-height: 24px;
  margin-bottom: 8px;
}
.img-overlay-listitem:nth-last-child(1) {
  margin-bottom: initial;
}
.splitpanes {
  height:auto;
  background-color: #FFF;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.splitpanes__pane {
  background-color: #FFF !important;
  padding: 10px;
}
.splitpanes__pane.active {
  border:1px solid #666;
}
.splitpanes__pane.active.left {
  border-right: 2px solid #666;
}
.splitpanes__pane.active.right {
  border-left:1px solid #666;
}
.splitpanes__pane.active.left.right {
  border:0;
}
.splitpanes__pane.left {
  border-bottom-left-radius: 12px;
}
.splitpanes__pane.right {
  border-bottom-right-radius: 12px;
}
.splitpanes__pane h3{
  font-size:18px;
  text-align: left;
  cursor: pointer;
}
.splitpanes__splitter {
  background-color: #EEE !important;
}
.img-overlay-content .img-overlay-list .list-group{
  height:100%;
  text-align: center;
  line-height:60px;
  white-space: nowrap;
}
.img-overlay-content.active .img-overlay-list .list-group {
  line-height:142px;
  white-space: nowrap;
}
.ghost {
  opacity: 0.5;
  background: #fff;
  border:1px dashed #CCC;
}
</style>
