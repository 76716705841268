<template>
  <vue-resizable
    class="resizable"
    :class="{ active: isCompare }"
    :fit-parent="fit"
    :width="width"
    :height="height"
    style="position:absolute;z-index:1;overflow:hidden;"
  >
    <slot></slot>
  </vue-resizable>
</template>

<script>
import VueResizable from 'vue-resizable';
import { mapGetters } from 'vuex';
import throttle from 'lodash/throttle';

export default {
  name: 'ResizeMap',
  props: [
    'isCompare',
  ],
  components: {
    VueResizable,
  },
  data() {
    return {
      height: '100%',
      width: '100%',
      fit: true,
    };
  },
  computed: {
    ...mapGetters(['mode']),
  },
  methods: {
    closeCompare() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    openCompare() {
      this.width = window.innerWidth / 2;
      this.height = window.innerHeight;
    },
    resize() {
      if (this.mode.value === 'side' || this.mode.value === 'overlay') {
        this.width = window.innerWidth / 2;
        this.height = window.innerHeight;
      } else {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
      }
    },
  },
  mounted() {
    this.throttleFunc = throttle(this.resize, 800);
    window.addEventListener('resize', this.throttleFunc);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.throttleFunc);
  },
};
</script>
<style lang='scss'>
  .resizable.active{
    position:relative;
    border-right:5px solid #F9D772;
  }
  .resizable.active::before{
    content: '=';
    position: absolute;
    top: calc(50% - 30px);
    right:-30px;
    text-align: center;
    line-height: 60px;
    height:60px;
    width:60px;
    border-radius: 50%;
    background-color: #F9D772;
    color:#666;
    z-index: 3;
    display: block;
    overflow:visible;
  }
</style>
