var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('GmapMap',{ref:"rightMap",staticClass:"w-100 h-100",staticStyle:{"position":"fixed","left":"0","top":"0","z-index":"1"},attrs:{"id":"map-canvas-right","center":{ lat: _vm.mapCenter.lat, lng: _vm.mapCenter.lng },"zoom":_vm.zoom,"options":{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUI: false,
      clickableIcons: false,
      disableDoubleClickZoom: true,
      minZoom: 8,
      styles: _vm.mapStyle,
    },"position":_vm.google && new _vm.google.maps.LatLng(1.38, 103.8)}},[(_vm.selectedMarker.position)?_c('GmapMarker',{attrs:{"position":_vm.selectedMarker.position,"clickable":false,"draggable":false,"icon":{ url: require('@/assets/img/spot.png')}}}):_vm._e()],1),_c('ResizeMap',{ref:"compareRefMap",attrs:{"isCompare":_vm.isCompare}},[_c('GmapMap',{ref:"leftMap",staticClass:"w-100 h-100",class:{ active: _vm.isSide },staticStyle:{"position":"absolute","left":"0","top":"0"},attrs:{"id":"map-canvas-left","center":{ lat: _vm.mapCenter.lat, lng: _vm.mapCenter.lng },"zoom":_vm.zoom,"options":{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false,
        clickableIcons: false,
        disableDoubleClickZoom: true,
        minZoom: 8,
        styles: _vm.mapStyle,
      },"position":_vm.google && new _vm.google.maps.LatLng(1.38, 103.8)}},[(!_vm.isCompare)?_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){_vm.infoWinOpen=false}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.infoContent)},on:{"click":function($event){return _vm.showInfoWindowContent(null)}}})]):_vm._e(),(!_vm.isCompare)?_c('div',_vm._l((_vm.photoMarkers),function(photomarker,photoindex){return _c('GmapMarker',{key:photoindex,attrs:{"position":photomarker.position,"clickable":true,"draggable":false,"icon":{ url: require('@/assets/img/photomarker.png')}},on:{"click":function($event){return _vm.toggleInfoWindow(photomarker, photoindex)}}})}),1):_vm._e(),(_vm.selectedMarker.position)?_c('GmapMarker',{attrs:{"position":_vm.selectedMarker.position,"clickable":false,"draggable":false,"icon":{ url: require('@/assets/img/spot.png')}}}):_vm._e(),(!_vm.isCompare && _vm.selectedMarker.position)?_c('GmapCircle',{attrs:{"center":_vm.selectedMarker.position,"radius":10000,"visible":true,"options":{fillColor:'yellow',fillOpacity:.1,strokeOpacity:0,clickable:false}}}):_vm._e(),_c('div',[_vm._l((_vm.measureOverlays),function(item,index){return _c('div',{key:index},[(item.isShow === true && item.type === 'polyline')?_c('gmap-polyline',{attrs:{"path":item.path,"options":{ strokeColor: '#ffcc00', strokeWeight: 4 }}}):_vm._e(),(item.isShow === true && item.type === 'polygon')?_c('gmap-polygon',{attrs:{"path":item.path,"options":{ strokeColor: '#a1a1d3' }}}):_vm._e()],1)}),(_vm.measureInfoWindowData)?_c('gmap-info-window',{attrs:{"options":{
            pixelOffset: {
              width: 0,
              height: -10,
            },
          },"position":_vm.measureInfoWindowData.position,"opened":_vm.measureInfoWindowData.isOpened},on:{"closeclick":_vm.closeMeasureToolInfoWindow}},[_c('div',[_c('span',{staticClass:"material-icons info-timeline"},[_vm._v("timeline")]),_c('div',[_c('span',{staticClass:"block",staticStyle:{"margin":"4px 0"}},[_vm._v("此段距離為")]),_c('span',{staticClass:"block",staticStyle:{"margin":"4px 0","font-weight":"700"}},[_vm._v(" "+_vm._s(_vm.getUnitValue())+" ")])])])]):_vm._e()],2),(_vm.enabledFunc.value === 'locationInfo' && _vm.locationInfoData.position)?_c('div',[_c('gmap-info-window',{attrs:{"options":{
            pixelOffset: {
              width: 0,
              height: -10,
            },
          },"position":_vm.locationInfoData.position,"opened":_vm.locationInfoData.isOpened},on:{"closeclick":_vm.closeLocationInfoWindow}},[_c('div',{staticClass:"location-info",staticStyle:{"text-align":"left"}},[_c('ul',[_c('li',[_vm._v("地址: "),_c('span',[_vm._v(_vm._s(_vm.locationInfoData.address))])]),_c('li',[_vm._v("經度: "),_c('span',[_vm._v(_vm._s(_vm.locationInfoData.position.lng))])]),_c('li',[_vm._v("緯度: "),_c('span',[_vm._v(_vm._s(_vm.locationInfoData.position.lat))])]),_c('li',[_vm._v("標高: "),_c('span',[_vm._v(_vm._s(_vm.getTwoDecimalPlaces(_vm.locationInfoData.elevation))+"m")])])])])])],1):_vm._e()],1)],1),_c('div',{staticClass:"main"},[_c('div',{staticClass:"main-mode pointer-event-none"},[_c('div',{staticClass:"main-mode-wrapper"},[_c('Header',{staticClass:"header-gap pointer-event-all",attrs:{"isSynchronize":_vm.isSynchronize,"isCompare":_vm.isCompare},on:{"get-marker-data":_vm.getMarkers,"set-mode":_vm.setMode,"toggle-synchronize":_vm.toggleSynchronize}}),_c('support-tool',{staticClass:"header-gap support-tool"}),_c('AddressInfo',{staticClass:"pointer-event-all",attrs:{"labels":_vm.locationDatas}}),(_vm.enabledFunc.value === 'elevation' && _vm.chartdata.labels.length > 0)?_c('div',{staticClass:"elevation-chart border-radius-square-soft pointer-event-all"},[_c('BarChart',{style:({ height: '100%', position: 'relative' }),attrs:{"chartdata":_vm.chartdata,"options":{
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: 'none',
              },
              scales: {
                yAxes: [{
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: '高程 (m)',
                  },
                }]
              },
            }}})],1):_vm._e()],1)]),_c('div',{staticClass:"main-side"},[_c('div',{staticClass:"main-side-wrapper"},[_c('div',{staticClass:"w-100 h-100 relative"},[_c('div',{staticClass:"main-side-container flex flex-col"},[_c('ImgOverlay',{attrs:{"overlaies":_vm.overlaies,"overlaiesCompare":_vm.overlaiesCompare,"isCompare":_vm.isCompare,"onCompare":_vm.onCompare},on:{"show-layer-data":_vm.openOverlay,"hide-layer-data":_vm.hideOverlay,"close-layer-data":_vm.closeOverlay,"sort-layer-data":_vm.sortOverlay,"show-compare-layer-data":_vm.openCompareOverlay,"hide-compare-layer-data":_vm.hideCompareOverlay,"close-compare-layer-data":_vm.closeCompareOverlay,"sort-compare-layer-data":_vm.sortCompareOverlay,"on-compare":_vm.setCompare}}),_c('TimeInfo',{staticClass:"main-side-block",staticStyle:{"flex":"1"},attrs:{"photos":_vm.photoMarkers,"layers":_vm.mapLayers,"overlaies":_vm.overlaies,"overlaiesCompare":_vm.overlaiesCompare,"onCompare":_vm.onCompare,"isCompare":_vm.isCompare},on:{"set-marker-data":_vm.setMarkers,"show-marker-data":_vm.showInfoWindowContent,"show-layer-data":_vm.openOverlay,"close-layer-data":_vm.closeOverlay,"show-compare-layer-data":_vm.openCompareOverlay,"close-compare-layer-data":_vm.closeCompareOverlay}})],1)])])])]),_c('Loading',{ref:"loading"}),_c('PhotoInfo',{ref:"photoinfo",attrs:{"datas":_vm.photoInfos}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }