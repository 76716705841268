const getters = {
  google: (state) => state.gmap.google,
  drawingManager: (state) => state.gmap.drawingManager,
  sidebar: (state) => state.app.sidebar,
  mode: (state) => state.app.mode,
  enabledFunc: (state) => state.app.enabledFunc,
  measureOverlays: (state) => state.measureTool.measureOverlays,
  measureInfoWindowData: (state) => state.measureTool.focusedOverlay.data,
  measureUnit: (state) => state.measureTool.unit,
  elevationPath: (state) => state.measureTool.focusedOverlay.elevationPath,
  locationInfoData: (state) => state.locationInfo.infoData,
};

export default getters;
