<template>
  <div :class="`dropdown-group ${initClass()}`">
    <button
      class="nav-btn border-radius-oval box-shadow"
      v-bind:class="{ active: isOpen }"
    >
      <input class="search-input"
        v-model="message"
        :placeholder="placeholder"
        @keyup.enter="searchData"
      >
      <span
        class="material-icons"
        @click="searchData"
      >search</span>
    </button>
    <transition name="dropdown-content">
      <ul
        class="dropdown-content box-shadow border-radius-square"
        aria-label="搜尋結果"
        v-if="isSearch"
      >
        <span class="material-icons dropdown-close" @click="closeWindow">close</span>
        <slot></slot>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SearchDropDown',
  props: {
    title: {
      type: String,
      require: true,
    },
    btnClass: {
      type: String,
    },
    initial: {
    },
  },
  data() {
    return {
      isOpen: true,
      isSearch: false,
      message: null,
      placeholder: null,
    };
  },
  methods: {
    searchData() {
      if (this.message !== '' && this.message !== null) {
        this.isSearch = true;
      } else {
        this.placeholder = '請輸入關鍵字';
      }
    },
    confirmSearch(data) {
      this.message = data;
    },
    closeWindow() {
      this.isSearch = false;
    },
    initClass() {
      if (this.initial === '') return 'dropdown-initial';
      return '';
    },
  },
};
</script>

<style lang='scss'>
.dropdown-group {
  display: inline-block;
  position: relative;
}
.dropdown-btn-text {
  margin: 0 2px;
}
.dropdown-content {
  width: 101%;
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  margin-top: 8px;
  padding: .35rem;
  background-color: #fff;
  cursor: pointer;
  user-select: none;
  z-index: 99;
}
.dropdown-initial {
  .nav-btn {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
  }
  .dropdown-content {
    width: 110%;
  }
}
// transition
.dropdown-content-enter-active,
.dropdown-content-leave-active {
  transition: all 0.2s;
}
.dropdown-content-enter,
.dropdown-content-leave-to {
  opacity: 0;
  transform: translate3d(-50%, -5px, 0);
}
.nav-btn .search-input{
  border:0 !important;
  outline:none !important;
  width:0;
  //display:none;
  transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
  float:left;
  font-size:16px;
  padding:0;
}
.nav-btn .material-icons{
  pointer-events: none;
}
.nav-btn.active .search-input{
  width:180px;
  padding:0px 8px;
  //display:block;
}
.nav-btn.active .material-icons{
  pointer-events: auto;
}
.nav-search ul:before{
    content:attr(aria-label);
    font-size:18px;
    font-weight:bold;
    margin-left:8px;
}
.dropdown-content .material-icons{
  position:absolute;
  top:10px;
  right:10px;
}
.search-input::placeholder {
  color: rgb(255, 146, 146);
}
</style>
