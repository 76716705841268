<template>
  <div class="clearfix">
    <div style="float: left;" class="pointer-event-all">
      <measure-tool-result v-if="google"/>
    </div>
    <div style="float: right" class="pointer-event-all">
      <drop-down
        v-if="measureUnit.value"
        :title="measureUnit.text"
        :btn-class="`${getClass(measureUnit.value, unitArr, 'nav-btn-on')}`"
        class="nav-gap support-container-btn"
      >
        <drop-down-item
          v-for="(item, index) in unitArr"
          :key="index"
          @click.native="setNewUnit(item)"
        >
          {{ item.text }}
        </drop-down-item>
      </drop-down>
      <div class="nav-gap support-container-btn" v-if="enabledFunc.value">
        <button
          class="nav-btn border-radius-oval box-shadow nav-btn-on"
          @click="closeDrawing"
        >
          <span style="margin-right: 4px;">{{ closeBtnText }}</span>
          <span class="material-icons">highlight_off</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MeasureToolResult from '@/components/MeasureToolResult/index.vue';
import { DropDown, DropDownItem } from '@/components/DropDown';
import { getClass } from '@/utils/styles';

export default {
  name: 'SupportTool',
  components: {
    MeasureToolResult,
    DropDown,
    DropDownItem,
  },
  data() {
    return {
      units: {
        polyline: [
          { text: '公里', value: 'kilometer' },
          { text: '公尺', value: 'meter' },
        ],
        polygon: [
          { text: '平方公尺', value: 'square meter' },
          { text: '平方公里', value: 'square kilometer' },
          { text: '公頃', value: 'hectare' },
        ],
      },
    };
  },
  computed: {
    closeBtnText() {
      const map = {
        elevation: '關閉地形高程',
        distance: '關閉測量距離',
        area: '關閉面積測量',
        locationInfo: '關閉地點資訊',
        satelliteImgInfo: '關閉衛星圖資',
      };
      return map[this.enabledFunc.value];
    },
    unitArr() {
      const map = {
        elevation: 'polyline',
        distance: 'polyline',
        area: 'polygon',
      };
      return this.units[map[this.enabledFunc.value]];
    },
    ...mapGetters(['drawingManager', 'google', 'measureUnit', 'enabledFunc']),
  },
  methods: {
    getClass,
    setNewUnit(data) {
      this.$store.dispatch('measureTool/setUnit', data);
    },
    closeDrawing() {
      this.$store.dispatch('app/setEnabledFunc', {});
      this.$store.dispatch('measureTool/clearAllOverlays');
      this.$store.dispatch('measureTool/clearFocusedOverlay');
      this.$store.dispatch('measureTool/setUnit', {});
      this.drawingManager.setDrawingMode(null);
    },
  },
};
</script>

<style lang='scss'>
.nav-gap {
  margin: 0 8px;
}
.support-container-btn {
  display: inline-block;
}
</style>
