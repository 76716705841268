<template>
  <div class="photo-block" v-if='isShow' v-bind:class="{ active: isShow }">
    <div class="
      photo-info
      border-radius-oval
      box-shadow
      animate__animated
      animate__pulse
    ">
      <span class="material-icons" @click="closeWindow">close</span>
      <h3>{{ datas.name }}</h3>
      <div class="photo-image">
        <img class="photo-image"
          :src="datas.url"
        >
      </div>
      <div class="photo-content">
        <h3>資訊</h3>
        <p class="photo-data">
          <span class="photo-data-left">經度:</span>
          <span class="photo-data-right">{{ datas.position.lng }}</span>
        </p>
        <p class="photo-data">
          <span class="photo-data-left">緯度:</span>
          <span class="photo-data-right">{{ datas.position.lat }}</span>
        </p>
        <p class="photo-data">
          <span class="photo-data-left">拍攝時間:</span>
          <span class="photo-data-right">{{ datas.time }}</span>
        </p>
        <p class="photo-data">
          <span class="photo-data-left">地址:</span>
          <span class="photo-data-right">{{ datas.address }}</span>
        </p>
        <p class="photo-data">
          <span class="photo-data-left">尺寸:</span>
          <span class="photo-data-right">{{ datas.size }}</span>
        </p>
        <p class="photo-data">
          <span class="photo-data-left">解析度:</span>
          <span class="photo-data-right">{{ datas.dpi }}</span>
        </p>
        <p class="photo-data">
          <span class="photo-data-left">機型:</span>
          <span class="photo-data-right">{{ datas.machine }}</span>
        </p>
        <p class="photo-data">
          <span class="photo-data-left">事件:</span>
          <span class="photo-data-right">{{ datas.event }}</span>
        </p>
        <button class="btn-yellow btn-radius btn-large">下載</button>
        <button class="btn-white btn-radius btn-large">加入下載清單</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhotoInfo',
  props: [
    'datas',
  ],
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    togglePhotoInfo() {
      this.isShow = !this.isShow;
    },
    closeWindow() {
      this.isShow = false;
    },
  },
};
</script>

<style lang='scss'>
.btn-yellow{
  background-color:#F9D772;
  border:1px solid #F9D772;
  color:#333;
  transition: all .5s ease-in-out;
  &:hover {
    background-color: #333;
    border:1px solid #333;
    color:#FFF;
  }
}
.btn-white{
  background-color:#fff;
  border:1px solid #333;
  color:#333;
  transition: all .5s ease-in-out;
  &:hover {
    color:#F9D772;
    border:1px solid #F9D772;
  }
}
.btn-large{
  width:100%;
  height: 42px;
  margin:6px 0;
  cursor: pointer;
}
.btn-large.btn-radius{
  border-radius: 21px;
}
.photo-block {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: rgba($color: #000000, $alpha: .2);
  transition: all 1s ease-in-out;
  z-index: 999;
  opacity: 0;
}
.photo-block.active{
  opacity: 1;
}
.photo-info {
  width: 862px;
  height: 500px;
  background-color: #FFF;
  position: absolute;
  top:calc(50% - 250px);
  left:calc(50% - 431px);
  padding:24px;
}
.photo-info .material-icons{
  position: absolute;
  top:20px;
  right:20px;
  font-size: 24px;
  cursor:pointer;
}
.photo-info h3{
  text-align: left;
  padding: 6px 0 16px 0;
}
.photo-image{
  width:64%;
  height:calc(100% - 50px);
  float:left;
  text-align: center;
}
.photo-image img {
  width: auto;
  height:auto;
  max-width:100%;
  max-height:100%;
}
.photo-content {
  width: 34%;
  float:right;
}
.photo-content h3 {
  border-bottom:1px solid #333;
  padding-bottom: 10px;
}
.photo-data {
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  color:gray;
  font-weight: 100;
}
.photo-data .photo-data-left{
  float:left;
}
.photo-data .photo-data-right{
  float:right;
}
</style>
