<template>
  <div :class="`dropdown-group ${initClass()}`">
    <button
      :class="`nav-btn border-radius-oval box-shadow ${btnClass !== undefined ? btnClass : ''}`"
      @click="toggleStatus"
      v-click-outside='closeWindow'
    >
      <span class="dropdown-btn-text">{{ this.title }}</span>
      <span class="material-icons" v-if='isOpen'>
        expand_less
      </span>
      <span class="material-icons" v-else>
        expand_more
      </span>
    </button>
    <transition name="dropdown-content">
      <ul
        class="dropdown-content box-shadow border-radius-square"
        v-if="isOpen"
      >
        <slot></slot>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DropDown',
  props: {
    title: {
      type: String,
      require: true,
    },
    btnClass: {
      type: String,
    },
    initial: {
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleStatus() {
      this.isOpen = !this.isOpen;
    },
    closeWindow() {
      this.isOpen = false;
    },
    initClass() {
      if (this.initial === '') return 'dropdown-initial';
      return '';
    },
  },
};
</script>

<style lang='scss'>
.dropdown-group {
  display: inline-block;
  position: relative;
}
.dropdown-btn-text {
  margin: 0 2px;
}
.dropdown-content {
  width: 101%;
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  margin-top: 8px;
  padding: .35rem;
  background-color: #fff;
  cursor: pointer;
  user-select: none;
  z-index: 99;
}
.dropdown-initial {
  .nav-btn {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
  }
  .dropdown-content {
    width: 110%;
  }
}
// transition
.dropdown-content-enter-active,
.dropdown-content-leave-active {
  transition: all 0.2s;
}
.dropdown-content-enter,
.dropdown-content-leave-to {
  opacity: 0;
  transform: translate3d(-50%, -5px, 0);
}

</style>
