const locationInfo = {
  namespaced: true,
  state: {
    infoData: {},
  },
  mutations: {
    SET_LOCATION_INFO(state, infoObj) {
      state.infoData = infoObj;
    },
    CLEAR_LOCATION_INFO(state) {
      state.infoData = {};
    },
    SET_INFO_WINDOW_STATUS(state, boolean) {
      state.infoData.isOpened = boolean;
    },
  },
  actions: {
    setLocationInfo({ commit }, infoObj) {
      commit('SET_LOCATION_INFO', infoObj);
    },
    clearLocationInfo({ commit }) {
      commit('CLEAR_LOCATION_INFO');
    },
    setInfoWindowStatus({ commit }, boolean) {
      commit('SET_INFO_WINDOW_STATUS', boolean);
    },
  },
};

export default locationInfo;
