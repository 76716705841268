<template>
  <li
    class="img-overlay-listitem list-group-item"
  >
    <span
      class="material-icons"
      v-bind:class="{ hide: isHide }"
      @click="toggleOverlay"
    >
      visibility
    </span>
    <span
      class="material-icons"
      @click="removeOverlay"
    >
      delete_outline
    </span>
    {{ index }}
    {{ item.name }}
  </li>
</template>

<script>
export default {
  name: 'OverlayBoxItem',
  data() {
    return {
      isExtend: false,
      isHide: false,
    };
  },
  props: [
    'index',
    'item',
    'overlaies',
  ],
  methods: {
    toggleOverlay() {
      this.isHide = !this.isHide;
      if (this.isHide) {
        this.$emit('hide-layer', this.item);
      } else {
        this.$emit('open-layer', this.item);
      }
    },
    removeOverlay() {
      if (this.overlaies.indexOf(this.item) !== -1) {
        this.$emit('close-layer', this.item);
      }
    },
  },
};
</script>
<style lang='scss'>
.img-overlay-listitem{
  border-radius:6px;
  background-color: #EEE;
  padding: 4px;
  cursor: grab;
  white-space: nowrap;
  overflow: hidden;
}
.img-overlay-listitem .material-icons{
  font-size: 22px;
  margin-right:4px;
  cursor: pointer;
  transition: all .1s ease-in-out;
}
.img-overlay-listitem .material-icons.hide{
  color:#999;
}
</style>
