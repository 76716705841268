<template>
  <div :class="`${initClass()}`">
    <div class="time-info-main-data-gap time-info-main-dataimg w-100 h-100" v-if="!isOpen">
      <div class="time-info-main-nondata">
        <img :src="require('@/assets/img/nondata.png')" style="object-fit:contain;">
      </div>
      <div class="time-info-main-nondata-text">你想找哪裡的照片？<br>先去搜尋一下吧！</div>
    </div>
    <transition name="layerbox-content" v-if="isOpen">
      <div class="time-info-main-data-gap w-100 h-100">
        <p>2020-08-03
          <span class="material-icons">
            expand_more
          </span>
        </p>
        <ul class="layer-group" v-bind:class="{ active: isCompare }">
          <slot></slot>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'LayerBox',
  props: [
    'isOpen',
    'isCompare',
  ],
  methods: {
    initClass() {
      if (this.initial === '') return 'layerbox-initial';
      return '';
    },
  },
};
</script>
<style lang='scss'>
.layer-group {
  height: 240px;
  overflow-y: auto;
}
.layer-group.active {
  height: 100%;
}
</style>
