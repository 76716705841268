const app = {
  namespaced: true,
  state: {
    sidebar: {
      opened: false,
    },
    mode: {
      text: '套疊模式',
      value: 'normal',
    },
    enabledFunc: {},
  },
  mutations: {
    TOGGLE_SIDEBAR(state) {
      state.sidebar.opened = !state.sidebar.opened;
    },
    CLOSE_SIDEBAR(state) {
      state.sidebar.opened = false;
    },
    SET_MODE(state, newMode) {
      state.mode = newMode;
    },
    SET_ENABLED_FUNC(state, newObj) {
      if (newObj.value === undefined) {
        state.enabledFunc = {};
        return;
      }
      state.enabledFunc = {
        text: newObj.text,
        value: newObj.value,
      };
    },
  },
  actions: {
    toggleSidebar({ commit }) {
      commit('TOGGLE_SIDEBAR');
    },
    closeSidebar({ commit }) {
      commit('CLOSE_SIDEBAR');
    },
    setMode({ commit }, newMode) {
      commit('SET_MODE', newMode);
    },
    setEnabledFunc({ commit }, newObj) {
      commit('SET_ENABLED_FUNC', newObj);
    },
  },
};

export default app;
